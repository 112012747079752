var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student-attachments"},[_c('p',{staticClass:"text-center error--text"},[_vm._v(" "+_vm._s(_vm.validationMsg)+" ")]),(_vm.attachmentsInputs && _vm.attachmentsInputs.length > 0)?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.attachmentsInputs),function(attachment,index){return _c('v-tab',{key:index,staticClass:"mb-4 justify-start"},[_vm._v(" "+_vm._s(attachment.name)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.attachmentsInputs),function(attachment,index){return _c('v-tab-item',{key:index,attrs:{"value":index}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"buttons-space"},[_c('v-col',{staticClass:"text-center",attrs:{"md":"12","sm":"12"}},[_c('div',{staticClass:"custom-file",attrs:{"id":"upload-photo"}},[_c('v-file-input',{ref:"fileObject",refInFor:true,staticClass:"custom-file-input",attrs:{"type":"file","accept":"image/*,.pdf"},on:{"change":function($event){return _vm.onFileUpload(
                          $event,
                          attachment.id,
                          index,
                          attachment.uploaded_file_id
                        )}}}),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFile"}}),_c('p',{staticClass:"error--text",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(attachment.validation_error)+" ")])],1),(!_vm.loaded)?_c('div',{staticClass:"loader text-center",staticStyle:{"width":"100%"}},[_c('img',{attrs:{"src":require("../../assets/Spinner-1s-200px.gif"),"alt":"","width":"100px"}})]):_vm._e()]),_c('v-col',{staticClass:"text-center",attrs:{"md":"12","sm":"12"}},[(
                      attachment.preview != undefined &&
                      !attachment.preview.includes('pdf')
                    )?_c('v-img',{staticStyle:{"margin":"20px auto","cursor":"pointer"},attrs:{"width":"50%","src":attachment.preview,"contain":""},on:{"click":function($event){return _vm.showImg(attachment.preview)}}}):(
                      attachment.preview != undefined &&
                      attachment.preview.includes('pdf')
                    )?_c('iframe',{attrs:{"src":attachment.preview,"width":"50%","height":"400px"}}):_vm._e()],1)],1)],1)],1)],1)}),1)],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }