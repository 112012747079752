<template>
  <div class="student-attachments">
    <p class="text-center error--text">
      {{ validationMsg }}
    </p>
    <v-form
      ref="form"
      v-model="valid"
      v-if="attachmentsInputs && attachmentsInputs.length > 0"
    >
      <v-tabs v-model="tab" vertical>
        <v-tab
          class="mb-4 justify-start"
          v-for="(attachment, index) in attachmentsInputs"
          :key="index"
        >
          {{ attachment.name }}
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(attachment, index) in attachmentsInputs"
            :key="index"
            :value="index"
          >
            <v-card flat>
              <v-card-text>
                <v-row class="buttons-space">
                  <v-col md="12" sm="12" class="text-center">
                    <div class="custom-file" id="upload-photo">
                      <v-file-input
                        type="file"
                        accept="image/*,.pdf"
                        class="custom-file-input"
                        ref="fileObject"
                        @change="
                          onFileUpload(
                            $event,
                            attachment.id,
                            index,
                            attachment.uploaded_file_id
                          )
                        "
                      ></v-file-input>
                      <label class="custom-file-label" for="customFile">
                      </label>
                      <p class="error--text" style="text-align: left">
                        {{ attachment.validation_error }}
                      </p>
                    </div>

                    <div
                      class="loader text-center"
                      v-if="!loaded"
                      style="width: 100%"
                    >
                      <img
                        src="../../assets/Spinner-1s-200px.gif"
                        alt=""
                        width="100px"
                      />
                    </div>
                  </v-col>
                  <v-col md="12" sm="12" class="text-center">
                    <v-img
                      width="50%"
                      v-if="
                        attachment.preview != undefined &&
                        !attachment.preview.includes('pdf')
                      "
                      :src="attachment.preview"
                      contain
                      @click="showImg(attachment.preview)"
                      style="margin: 20px auto; cursor: pointer"
                    ></v-img>
                    <iframe
                      v-else-if="
                        attachment.preview != undefined &&
                        attachment.preview.includes('pdf')
                      "
                      :src="attachment.preview"
                      width="50%"
                      height="400px"
                    >
                    </iframe>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-file-input
                      :rules="rules"
                      accept="image/*,.pdf,.doc,.docx,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      chips
                      solo
                      @change="
                        onFileUpload(
                          $event,
                          attachment.id,
                          index,
                          attachment.uploaded_file_id
                        )
                      "
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <a
                      :href="attachment.preview"
                      class="btn preview"
                      target="_blank"
                      v-if="attachment.preview"
                      >{{ $t("Preview") }}</a
                    >
                  </v-col>
                </v-row> -->
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "studentAttachments",
  props: ["studentAttachments", "validationMsg"],
  data() {
    return {
      tab: 0,
      attachmentsInputs: [],
      valid: false,
      loaded: true,
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Attachment size should be less than 5 MB!",
      ],
      uploadedFile: "",
    };
  },
  watch: {
    studentAttachments: function (newValue) {
      this.attachmentsInputs = newValue;
    },
    deep: true,
  },
  methods: {
    showImg(url) {
      window.open(url, "_blank");
    },
    checkErrorsExists() {
      return this.attachmentsInputs.some((item) => {
        item.validation_error;
      });
    },
    onFileUpload(event, id, index, uploaded_file_id) {
      console.log(event);
      if (event) {
        this.loaded = false;
        this.uploadedFile = event;
        const formData = new FormData();
        formData.append("file", this.uploadedFile);
        formData.append("student_code", this.$route.params.applicant_code);
        formData.append("document_id", id);

        this.$http
          .post(this.getApiUrl + "/students/uploadStudentDocument", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              let url = response.data.data.url;
              let uploaded_file_id = response.data.data.id;
              this.$set(this.attachmentsInputs, index, {
                ...this.attachmentsInputs[index],
                preview: url,
              });
              this.$set(this.attachmentsInputs, index, {
                ...this.attachmentsInputs[index],
                uploaded_file_id: uploaded_file_id,
              });

              this.attachmentsInputs[index].uploaded = true;
              this.loaded = true;
              this.$emit("updateStudentAttachments", this.attachmentsInputs);
            }
          });
      } else {
        this.$http
          .get(
            this.getApiUrl +
              "/students/removeFileStudentDocument/" +
              uploaded_file_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.$set(this.attachmentsInputs, index, {
                ...this.attachmentsInputs[index],
                preview: "",
              });
              this.$set(this.attachmentsInputs, index, {
                ...this.attachmentsInputs[index],
                uploaded_file_id: "",
              });

              this.attachmentsInputs[index].uploaded = false;
              this.$emit("updateStudentAttachments", this.attachmentsInputs);
            }
          });
      }
    },
    getStudentAttachments() {
      this.$http
        .get(this.getApiUrl + "/students/getDocumentsToForm", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.attachmentsInputs = response.data.data;
            this.$emit("updateStudentAttachments", this.attachmentsInputs);
          }
        });
    },
  },
  mounted() {
    this.getStudentAttachments();
  },
};
</script>

<style lang="scss" scoped>
.btn.preview {
  display: inline-block;
  margin-top: 3rem;
  padding: 10px 20px;
  background-color: #7296fb;
  color: #fff;
}
.buttons-space {
  margin-bottom: 40px;
  margin-top: 30px;
}
.custom-file {
  cursor: pointer;
  width: 50%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  .custom-file-label {
    cursor: pointer;
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      cursor: pointer;
      background-color: unset !important;
      color: #7297ff;
      margin-top: 0%;
      width: 100%;
      top: -6px !important;
    }
  }
}
</style>
