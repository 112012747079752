<template>
  <div class="admission-page complete-StudentProfile" id="admission-page">
    <h2 class="main-title">{{ $t("Complete Student Profile") }}</h2>
    <v-row v-if="!messageShow">
      <v-col
        class="side-page d-none d-md-block"
        cols="12"
        md="12"
        sm="12"
        style="max-height: 200px"
      >
        <svg
          version="1.1"
          id="e7b3b0c3-1e4f-480c-906c-c03021e90d10"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 991.1 770.2"
          style="enable-background: new 0 0 991.1 770.2"
          xml:space="preserve"
        >
          <title>{{ $t("professor") }}</title>
          <path
            class="st0"
            d="M289.6,0L131.7,85.5c11.1,19.2,4.6,43.8-14.6,54.9C98,151.5,73.4,145,62.3,125.8c-0.4-0.7-0.8-1.4-1.1-2.1
	L7.3,152.9l139.4,257.4L429,257.4L289.6,0z"
          />
          <circle class="st1" cx="94.6" cy="105.7" r="32.6" />
          <path
            class="st0"
            d="M686,689.1l170.7-55.7c-7.5-20.9,3.3-43.9,24.2-51.4s43.9,3.3,51.4,24.2c0.3,0.8,0.5,1.5,0.7,2.3l58.2-19
	l-90.8-278.3l-305.2,99.5L686,689.1z"
          />
          <circle class="st1" cx="896.8" cy="620.2" r="32.6" />
          <rect x="115.5" y="152.7" class="st2" width="788" height="358" />
          <rect x="128.5" y="165.7" class="st3" width="762" height="325" />
          <rect x="739" y="475.2" class="st2" width="74" height="25" />
          <rect x="681" y="494.2" class="st1" width="54" height="7" />
          <polyline
            class="st4"
            points="190.5,288.7 481.5,288.7 526.6,310.7 598.5,345.7 645.5,257.7 778.5,257.7 "
          />
          <polyline class="st4" points="304.9,288.7 326.5,339.7 379.5,339.7 " />
          <polyline class="st4" points="385.8,288.7 459.5,248.7 511.5,248.7 " />
          <circle class="st1" cx="304.5" cy="288.7" r="9" />
          <circle class="st1" cx="388.5" cy="288.7" r="9" />
          <polyline class="st4" points="526.5,310.7 532.5,399.7 584.5,399.7 " />
          <polyline class="st4" points="626.7,293 569.5,248.7 623.5,248.7 " />
          <polyline class="st4" points="721.1,257.7 776.5,301.7 828.5,301.7 " />
          <circle class="st1" cx="526.5" cy="310.7" r="9" />
          <circle class="st1" cx="720.5" cy="257.7" r="9" />
          <circle class="st1" cx="626.5" cy="292.7" r="9" />
          <line class="st4" x1="327.5" y1="347.7" x2="379.5" y2="347.7" />
          <path class="st4" d="M511.5,257.7L511.5,257.7z" />
          <path class="st4" d="M623.5,256.7L623.5,256.7z" />
          <path class="st4" d="M623.5,264.7L623.5,264.7z" />
          <path class="st4" d="M828.5,309.7L828.5,309.7z" />
          <path class="st4" d="M809.5,317.7L809.5,317.7z" />
          <path class="st4" d="M584.5,407.7L584.5,407.7z" />
          <circle class="st4" cx="809.5" cy="75.5" r="14.8" />
          <ellipse class="st0" cx="152" cy="742.2" rx="152" ry="28" />
          <polygon
            class="st1"
            points="256.4,493.7 229.3,507.3 231,510.8 258.1,497.2 "
          />
          <path
            class="st5"
            d="M252,468.2l-1.2,7.3c0,0,1.8,50.6-11.6,34.7s-4.3-37.2-4.3-37.2l3.7-6.1L252,468.2z"
          />
          <path
            class="st5"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <path
            class="st6"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <polygon
            class="st2"
            points="128.9,700.9 128.9,722.8 120.4,724 108.2,720.4 111.9,697.2 "
          />
          <path
            class="st7"
            d="M118,711.9c0,0-3.7-9.7-9.7-8.5s-7.3,8.5-7.3,8.5s-4.9,18.3-12.2,24.4s-14.6,21.9,0,24.4s26.8-3.7,26.8-4.9
	s3.7-8.5,9.7-9.7c6.1-1.2,11-2.4,11-7.3s-3.7-9.7-3.7-9.7s-1.2-23.1-3.7-20.1c-1.7,2.4-2.9,5.1-3.7,7.9L118,711.9z"
          />
          <polygon
            class="st2"
            points="181.3,689.9 176.4,708.2 188.6,710.6 194.7,704.5 195.9,688.7 "
          />
          <path
            class="st7"
            d="M180.1,705.8c0,0,4.9-3.7,7.3-3.7h3.7c0,0,0-8.5,6.1-8.5s4.9,6.1,4.9,6.1s13.4,20.7,17.1,24.4
	s23.1,14.6,7.3,19.5s-26.8,4.9-34.1-3.7c0,0-7.3-3.7-12.2-3.7s-11-2.4-11-6.1s3.7-11,3.7-11s2.4-21.9,3.7-19.5
	S180.1,705.8,180.1,705.8z"
          />
          <path
            class="st7"
            d="M127.7,409.7v40.2c0,0-13.4,78-13.4,103.5s-20.7,147.4-11,149.8s32.9,6.1,34.1,2.4s9.7-119.4,11-120.6
	c1.2-1.2,26.8-97.5,26.8-97.5l4.9,1.2l2.4,109.6c0,0-12.2,98.7-6.1,98.7s31.7-1.2,31.7-8.5s13.4-142.5,13.4-142.5
	S237.4,439,231.3,428s-6.1-17.1-6.1-17.1L127.7,409.7z"
          />
          <circle class="st5" cx="194.1" cy="228.8" r="24.4" />
          <path
            class="st8"
            d="M188.6,270.9c0,0-15.2-12.8-16.4-17.7c0,0-7.9,7.9-7.9,9.1s-34.1,4.9-42.6,14.6s-15.8,30.5-15.8,30.5l20.7,39
	c0,0,1.2,21.9,1.2,24.4s-6.1,41.4-3.7,42.6s26.8,13.4,47.5,11s41.4-6.1,47.5-6.1s11,2.4,11,0s-3.7-7.3-3.7-9.7s-1.2-8.5-1.2-12.2
	s-2.4-9.7-2.4-14.6s26.8-70.7,26.8-70.7s-1.2-20.7-20.7-30.5s-21.9-13.4-21.9-13.4s-0.4-7.3-1.4-7.3s-4.7,11-14.4,12.2L188.6,270.9z
	"
          />
          <polygon
            class="st5"
            points="122.8,432.9 131.4,446.3 153.3,441.4 137.5,423.1 "
          />
          <path
            class="st8"
            d="M242.2,301.3l7.3,9.7c0,0,14.6,70.7,12.2,96.2s-4.9,45.1-4.9,45.1s4.9,18.3,1.2,18.3s-25.6,1.2-25.6-2.4
	s2.4-26.8,2.4-26.8l-6.1-53.6l-13.4-18.3L242.2,301.3z"
          />
          <path
            class="st7"
            d="M177.6,217c1.5-0.6,3.2-0.1,4.8-0.4c1.9-0.3,3.6-1.7,5.5-1.5c0.9,0.2,1.8,0.5,2.6,0.8c0.8,0.4,1.8,0.4,2.7,0.1
	c0.8-0.4,1.1-1.3,0.7-2c0-0.1-0.1-0.2-0.2-0.3c1.6-0.1,3.1-0.8,4.3-1.9c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.1,2.1,0.3l2.8,1.3
	c0.6,0.2,1.2,0.6,1.6,1.1c0.3,0.5,0.4,1.2,0.9,1.6c0.6,0.5,1.5,0.3,2.3,0.3c1.5,0.1,2.6,1.4,2.6,2.8c0,0.8-0.3,1.8,0.3,2.3
	c0.4,0.3,0.9,0.2,1.3,0.4c1.1,0.5,1.1,2,1,3.3s-0.1,2.8,1.1,3.2c0.8,0.3,1.7-0.3,2.5-0.4c1.3-0.2,2.5,0.6,3.6,1.2s2.7,1,3.7,0.2
	c0.9-0.8,0.9-2.2,0.6-3.4c-0.4-1.7-1-3.3-1.8-4.9c-0.3-0.5-0.5-1-0.5-1.5c0.1-0.8,0.6-1.5,1.3-1.9c1.4-1.1,3-2.2,3.5-4
	c0.3-1.3,0-2.6,0-3.9c0-1.6,0.7-3.2,0.4-4.8c-0.5-2.9-3.7-4.2-5.8-6.3c-2.8-2.8-3.7-7.4-7.1-9.6c-2.7-1.8-6.1-1.6-9.2-2.6
	c-3.3-1.1-6-3.7-9.3-4.9c-3.6-1.3-7.7-1-11,1c-1,0.7-2.1,1.3-3.2,1.7c-1,0.1-2,0.1-2.9,0c-1.9-0.1-3.9,0.3-5.6,1.2
	c-0.7,0.3-1.2,0.8-1.6,1.4c-0.6,1.3,0.3,3.4-1,4c-0.6,0.3-1.3,0-2,0c-1.3,0-2.3,1.2-2.9,2.3c-0.5,1.2-1.2,2.3-2,3.3
	c-1.6,1.4-4.5,1.6-4.9,3.7c0,0.9,0,1.8,0.1,2.6c-0.1,1.8-2.1,3.2-2,5.1c0.1,2.1,2.8,3.2,3.5,5.1c0.5,1.5-0.2,3.2-0.7,4.7
	s-0.6,3.5,0.6,4.5c0.5,0.4,1.1,0.5,1.7,0.9c1.3,1,1.3,2.9,1.2,4.5c-0.2,0.7,0,1.4,0.5,1.9c0.4,0.3,1,0.3,1.5,0.2
	c1.6-0.4,3-1.4,3.8-2.9c0.4-0.7,0.5-1.3,1.3-1.7c0.5-0.3,1.2,0,1.7-0.4C175.3,222.8,174.4,218.3,177.6,217z"
          />
          <path
            class="st8"
            d="M109.4,303.7l-3.7,3.7c0,0-21.9,65.8-14.6,80.4s21.9,51.2,26.8,49.9s21.9-12.2,21.9-13.4S119.2,389,119.2,389
	s-2.4-7.3-1.2-8.5s1.2,0,0-2.4s-1.2,0,1.2-2.4s2.4-2.4,2.4-3.7s7.3-31.7,7.3-31.7L109.4,303.7z"
          />
          <polygon
            class="st7"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon
            class="st6"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon class="st6" points="164.9,262.9 185.6,280 188,276.3 " />
          <polygon class="st6" points="206.3,267.8 191.7,276.3 192.9,280 " />
        </svg>
      </v-col>
    </v-row>

    <v-row v-if="!messageShow">
      <v-col cols="12" md="12" sm="12" v-if="!submitted">
        <!-- <div class="message">
          <div class="success--text text-center">
            <span class="success-icon">
              <i class="fas fa-check-circle"></i>
            </span>
            <h2>{{ successMsg }}</h2>
          </div>

          <p><strong>Applicant Name: </strong>{{ applicant_name }}</p>
          <p><strong> Application Number:</strong> {{ requestNum }}</p>
        </div> -->

        <!-- ================== start steps ======================== -->
        <div class="stepper">
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                :complete="step == 1 ? true : true"
                :complete-icon="'mdi-human-child'"
                step="1"
                :class="currentStep >= 1 ? 'active' : ''"
              >
                {{ $t("Student Info") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :complete="step == 2 ? true : true"
                step="2"
                :complete-icon="'mdi-account-outline'"
                :class="currentStep >= 2 ? 'active' : ''"
              >
                {{ $t("Father Info") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :complete="step == 3 ? true : true"
                step="3"
                :complete-icon="'mdi-mother-heart'"
                :class="currentStep >= 3 ? 'active' : ''"
              >
                {{ $t("Mother Info") }}
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step
                :complete="step == 4 ? true : true"
                step="4"
                :complete-icon="'mdi-account-group-outline'"
                :class="currentStep >= 4 ? 'active' : ''"
              >
                {{ $t("Sibling Info (optional)") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step == 5 ? true : true"
                step="5"
                :complete-icon="'mdi-office-building'"
                :class="currentStep >= 5 ? 'active' : ''"
              >
                {{ $t("Previous School (optional)") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step == 6 ? true : true"
                step="6"
                :complete-icon="'mdi-medical-bag'"
                :class="currentStep >= 6 ? 'active' : ''"
              >
                {{ $t("Student Medical History") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step == 7 ? true : true"
                :complete-icon="'mdi-human-child'"
                step="7"
                :class="currentStep >= 7 ? 'active' : ''"
              >
                {{ $t("Student Documents") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :complete="step == 8 ? true : true"
                step="8"
                :complete-icon="'mdi-account-check-outline'"
                :class="currentStep >= 8 ? 'active' : ''"
              >
                {{ $t("Survay (optional)") }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <!-- ================== end start steps ======================== -->

        <v-form ref="form1" class="student-form">
          <!-- start section 1 -->
          <section class="form-page profile-section" :hidden="currentStep != 1">
            <h4>{{ $t("Student Information") }}</h4>
            <v-row>
              <!-- first Tab first section -->
              <v-col cols="12" sm="6" class="pa-0">
                <v-row>
                  <v-col cols="12" md="4"
                    ><label>{{ $t("Upload Photo") }} </label></v-col
                  >
                  <v-col cols="12" md="8" class="logo-div-img">
                    <v-row>
                      <v-col cols="12" sm="6" class="pa-0">
                        <div class="custom-file" id="upload-photo">
                          <input
                            type="file"
                            class="custom-file-input"
                            @change="onFileSelected"
                            :rules="validationRules[1].image"
                          />
                          <label class="custom-file-label" for="customFile">
                            <br
                          /></label>
                          <!-- <div v-if="imageError == true">
                            <p style="color:red;">
                              Image is required
                            </p>
                          </div> -->
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-0">
                        <img
                          src="../../assets/img/preloader.gif"
                          id="preloader-custom"
                          alt="preloader"
                          class="hidden"
                        />
                        <img
                          style="width: 50px; height: 50px"
                          v-if="studentData.image"
                          id="logo"
                          class="rounded-circle asd"
                          :src="'https://sms.test' + studentData.image"
                          alt
                        />
                        <img
                          v-if="
                            studentData.image == null || studentData.image == ''
                          "
                          id="logo"
                          class="rounded-circle"
                          width="50px"
                          src="../../assets/school_logo.png"
                        />
                      </v-col>
                    </v-row>
                    <!-- <img :src="studentData.image" class="mg-t-30" /> -->
                    <div v-if="true">
                      <p style="color: red; margin-top: -20px">
                        {{ logoError }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("First Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="applicantObject.firstname"
                      dense
                      solo
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="father-name">{{ $t("Father's Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="applicantObject.fathername"
                      dense
                      solo
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="family-name">{{ $t("Family Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="applicantObject.familyname"
                      dense
                      solo
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="gender">{{ $t("Gender") }}</label>
                  </v-col>
                  <v-col cols="12" md="8"
                    ><div class="">
                      <v-radio-group v-model="applicantObject.gender" row>
                        <v-radio
                          disabled
                          :label="$t('Male')"
                          value="male"
                        ></v-radio>
                        <v-radio
                          disabled
                          :label="$t('Female')"
                          value="female"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4"
                    ><label for="religion-options">{{ $t("Religion") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="dataObject.religion"
                      item-text="key"
                      item-value="value"
                      v-model="applicantObject.religion"
                      dense
                      solo
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- first Tab second section -->
              <v-col cols="12" sm="6" class="pa-0">
                <v-row>
                  <v-col cols="12" md="4"
                    ><label for="geo-date">{{ $t("Date of Birth") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="gregorian"
                      autocomplete="off"
                      v-model="applicantObject.date_of_birth"
                      append-icon="mdi-calendar"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4"
                    ><label for="geo-date">{{
                      $t("Hijri Date of Birth")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="hijri-date"
                      autocomplete="off"
                      v-model="applicantObject.date_of_birth_hijri"
                      append-icon="mdi-calendar"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <label>{{ $t("Place of Birth") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      autocomplete="off"
                      v-model="applicantObject.place_of_birth"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4"
                    ><label for="religion-options">{{
                      $t("Nationality")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="dataObject.nationalities"
                      item-text="name"
                      item-value="id"
                      v-model="applicantObject.nationality"
                      dense
                      solo
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="applicantObject.national_id">
                  <v-col cols="12" md="4">
                    <label>{{ $t("National ID") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      autocomplete="off"
                      v-model="applicantObject.national_id"
                      solo
                      dense
                      disabled
                      :rules="[
                        checkNationalIdUnique(
                          applicantObject.national_id,
                          studentData.father.national_id,
                          studentData.mother.national_id
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="applicantObject.passport_no">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Passport Number") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      autocomplete="off"
                      v-model="applicantObject.passport_no"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="applicantObject.iqama_no">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Iqama Number") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      autocomplete="off"
                      v-model="applicantObject.iqama_no"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="applicantObject.iqama_expiry_date">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Iqama Expiry Date") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      autocomplete="off"
                      v-model="applicantObject.iqama_expiry_date"
                      solo
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- <small>
              <span style="color:red">Fields with * are required.</span></small
            > -->
          </section>
          <!-- end section 1 -->
        </v-form>

        <v-form ref="form2" class="student-form" v-model="valid[2]">
          <!-- start section 2 -->
          <section class="form-page profile-section" :hidden="currentStep != 2">
            <h4>{{ $t("Father Information") }}</h4>
            <v-row>
              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("First Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.firstname"
                      :rules="validationRules[2].firstname"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Middle Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.middlename"
                      :rules="validationRules[2].middlename"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Family Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.familyname"
                      :rules="validationRules[2].familyname"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4">
                    <label for="nationality">{{ $t("Nationality") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      :items="dataObject.nationalities"
                      item-text="name"
                      item-value="id"
                      v-model.trim="studentData.father.nationality_id"
                      :rules="[validation.required]"
                      :select="fatherNationalityOnSelect()"
                      solo
                    >
                    </v-autocomplete>
                    <!-- :select="onSelect()" -->
                  </v-col>
                </v-row>

                <v-row v-if="father_saudi">
                  <v-col cols="12" md="4">
                    <label for="national-id">{{ $t("National ID") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="national-id"
                      v-model.trim="studentData.father.national_id"
                      :rules="[
                        validation.required,
                        validation.numeric,
                        checkNationalIdUniqueFather(
                          applicantObject.national_id,
                          studentData.father.national_id,
                          studentData.mother.national_id
                        ),
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item" v-if="!father_saudi">
                  <v-col cols="12" md="4"
                    ><label>{{ $t("Iqama No") }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="iqama-number"
                      v-model.trim="studentData.father.parent_iqama_no"
                      :rules="[validation.required, validation.alphaNum]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!father_saudi">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Expiry Date") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="expiry"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.trim="
                        studentData.father.parent_iqama_no_expire_date
                      "
                      :rules="[validation.required]"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Education Level") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="eduLevels"
                      v-model="studentData.father.education_level"
                      :rules="validationRules[2].education_level"
                      solo
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Occupation") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.occupation"
                      :rules="validationRules[2].occupation"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Business Address") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.business_address"
                      :rules="validationRules[2].business_address"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Home Tel. Number") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.tel"
                      :rules="validationRules[2].tel"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Mobile Number") }}</label>
                  </v-col>
                  <v-col cols="12" md="3" class="countryCode">
                    <v-row>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        style="padding-top: 0"
                        class="countryCode"
                      >
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            required
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="studentData.father.country_id"
                            ><template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }} -->

                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col> </v-row
                  ></v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="studentData.father.mobile"
                      :rules="[
                        mobileRules.required,
                        mobileRules.foramt,
                        mobileRule(
                          studentData.father.country_id,
                          studentData.father.mobile
                        ),
                        checkThatValuesNotSame(
                          studentData.father.mobile,
                          studentData.mother.mobile,
                          'mobile'
                        ),
                      ]"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Email") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.father.email"
                      :rules="[
                        validation.email,
                        validation.required,

                        checkThatValuesNotSame(
                          studentData.father.email,
                          studentData.mother.email,
                          'email'
                        ),
                      ]"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </section>
          <!-- end section 2 -->
        </v-form>

        <v-form ref="form3" class="student-form" v-model="valid[3]">
          <!-- start section 3 -->
          <section class="form-page profile-section" :hidden="currentStep != 3">
            <h4>{{ $t("Mother Information") }}</h4>

            <v-row>
              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("First Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.firstname"
                      :rules="validationRules[3].firstname"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Middle Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.middlename"
                      :rules="validationRules[3].middlename"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Family Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.familyname"
                      :rules="validationRules[3].familyname"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4">
                    <label for="nationality">{{ $t("Nationality") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      :items="dataObject.nationalities"
                      item-text="name"
                      item-value="id"
                      v-model.trim="studentData.mother.nationality_id"
                      :rules="[validation.required]"
                      :select="motherNationalityOnSelect()"
                      solo
                    >
                    </v-autocomplete>
                    <!-- :select="onSelect()" -->
                  </v-col>
                </v-row>

                <v-row v-if="mother_saudi">
                  <v-col cols="12" md="4">
                    <label for="national-id">{{ $t("National ID") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="national-id"
                      v-model.trim="studentData.mother.national_id"
                      :rules="[
                        validation.required,
                        validation.numeric,
                        checkNationalIdUniqueMother(
                          applicantObject.national_id,
                          studentData.father.national_id,
                          studentData.mother.national_id
                        ),
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!mother_saudi">
                  <v-col cols="12" md="4"
                    ><label>{{ $t("Iqama No") }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="iqama-number"
                      v-model.trim="studentData.mother.parent_iqama_no"
                      :rules="[validation.required, validation.alphaNum]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!mother_saudi">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Expiry Date") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="motherIqamaexpiry"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.trim="
                        studentData.mother.parent_iqama_no_expire_date
                      "
                      :rules="[validation.required]"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Education Level") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="eduLevels"
                      v-model="studentData.mother.education_level"
                      :rules="validationRules[3].education_level"
                      solo
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Occupation") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.occupation"
                      :rules="validationRules[3].occupation"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Business Address") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.business_address"
                      :rules="validationRules[3].business_address"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Home Tel. Number") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.tel"
                      :rules="validationRules[3].tel"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Mobile Number") }}</label>
                  </v-col>
                  <v-col cols="12" md="3" class="countryCode">
                    <v-row>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        style="padding-top: 0"
                        class="countryCode"
                      >
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            required
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="studentData.mother.country_id"
                            ><template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }} -->

                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col> </v-row
                  ></v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="studentData.mother.mobile"
                      :rules="[
                        mobileRules.required,
                        mobileRules.foramt,
                        mobileRule(
                          studentData.mother.country_id,
                          studentData.mother.mobile
                        ),
                        checkThatValuesNotSame(
                          studentData.father.mobile,
                          studentData.mother.mobile,
                          'mobile'
                        ),
                      ]"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{ $t("Email") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="studentData.mother.email"
                      :rules="[
                        validation.email,
                        validation.required,

                        checkThatValuesNotSame(
                          studentData.father.email,
                          studentData.mother.email,
                          'email'
                        ),
                      ]"
                      required
                      @focus="onFocus()"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name">{{
                      $t("Parents Relationship")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-radio-group
                      v-model="studentData.parents_relationship"
                      row
                      :rules="validationRules[3].parents_relationship"
                    >
                      <v-radio value="married" :label="$t('Married')">
                      </v-radio>
                      <v-radio value="divorced" :label="$t('Divorced')">
                      </v-radio>
                      <v-radio value="other" :label="$t('Other')"> </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="studentData.parents_relationship == 'other'">
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="studentData.parents_relationship_other"
                      :rules="validationRules[3].parents_relationship_other"
                      @focus="onFocus()"
                      dense
                      autocomplete="off"
                      solo
                      placeholder="Please specify"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </section>
          <!-- end section 3 -->
        </v-form>

        <v-form ref="form4" class="student-form" v-model="valid[4]">
          <!-- start section 4 -->
          <section class="form-page profile-section" :hidden="currentStep != 4">
            <h4>Siblings Information (For Emergency)</h4>

            <div v-for="(item, index) in studentData.siblings" :key="index">
              <v-row>
                <v-col cols="12" sm="6" class="pa-0">
                  <v-row class="form-item">
                    <v-col cols="12" md="4">
                      <label for="first-name">{{ $t("Name") }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="item.name"
                        :rules="validationRules[4].name"
                        dense
                        autocomplete="off"
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    class="form-item"
                    style="margin-top: -17px; margin-bottom: -9px"
                  >
                    <v-col cols="12" md="4"
                      ><label for="gender">{{ $t("Gender") }}</label>
                    </v-col>
                    <v-col cols="12" md="8"
                      ><div class="">
                        <v-radio-group
                          v-model="item.gender"
                          :rules="validationRules[4].gender"
                          row
                        >
                          <v-radio :label="$t('Male')" value="male"></v-radio>
                          <v-radio
                            :label="$t('Female')"
                            value="female"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="form-item">
                    <v-col cols="12" md="4">
                      <label for="first-name">{{ $t("Mobile Number") }}</label>
                    </v-col>
                    <v-col cols="12" md="3" class="">
                      <v-row>
                        <v-col
                          cols="12"
                          xs="12"
                          sm="12"
                          style="padding-top: 0"
                          class="px-0"
                        >
                          <div class="code" id="country-code">
                            <v-autocomplete
                              :items="dataObject.Codes"
                              class="form-control"
                              required
                              @change="hideLabel = true"
                              outlined
                              item-text="title"
                              item-value="value"
                              value="187"
                              v-model="item.country_id"
                              ><template slot="selection" slot-scope="data">
                                <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }} -->

                                <v-flex xs2>
                                  <v-avatar size="25px">
                                    <img :src="data.item.icon" />
                                  </v-avatar>
                                </v-flex>
                                <v-flex class="ml-4">
                                  {{ data.item.title }}
                                </v-flex>
                              </template>
                              <template slot="item" slot-scope="data">
                                <v-list-item-avatar
                                  style="
                                    width: 20px;
                                    min-width: 20px;
                                    height: 20px;
                                  "
                                >
                                  <img :src="data.item.icon" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="data.item.title"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </div>
                        </v-col> </v-row
                    ></v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="item.mobile"
                        :rules="[
                          mobileRules.required,
                          mobileRules.foramt,
                          mobileRule(item.country_id, item.mobile),
                        ]"
                        required
                        @focus="onFocus()"
                        dense
                        solo
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="pa-0">
                  <v-row>
                    <v-col cols="12" md="4"
                      ><label for="geo-date">{{ $t("Date of Birth") }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        :id="'gregorian' + index"
                        autocomplete="off"
                        v-model="item.date_of_birth"
                        :rules="validationRules[4].date_of_birth"
                        dense
                        solo
                        :data-index="index"
                        @keydown.prevent
                      ></v-text-field>
                      <v-icon
                        large
                        title="Date of Birth"
                        class="mr-2 main-color dateIcon"
                        >event</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row class="form-item">
                    <v-col cols="12" md="4">
                      <label for="first-name">{{ $t("School") }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="item.school"
                        :rules="validationRules[4].school"
                        required
                        @focus="onFocus()"
                        dense
                        solo
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <hr />
            </div>
            <v-row>
              <!-- add_circle -->
              <v-col cols="12" md="12" style="text-align: center">
                <v-btn
                  large
                  :title="$t('Add Siblings')"
                  class="main-btn"
                  @click="addSibling"
                  >{{ $t("Add siblings") }}</v-btn
                >
                <v-btn
                  large
                  title="Delete Sibling"
                  class="deleteSiblingBtn"
                  @click="deleteSibling(index)"
                  >{{
                    this.studentData.siblings.length == 1 ||
                    this.studentData.siblings.length == 0
                      ? $t("Skip")
                      : $t("Delete")
                  }}</v-btn
                >
              </v-col>
            </v-row>
          </section>
          <!-- end section 4 -->
        </v-form>
        <v-form ref="form5" class="student-form" v-model="valid[5]">
          <!-- start section 5 -->
          <section class="form-page profile-section" :hidden="currentStep != 5">
            <h4 style="margin-bottom: 5px">{{ $t("Previous Schools") }}</h4>
            <small>{{ $t("Please begin with present school") }}</small>
            <div
              v-for="(item, index) in studentData.academic_history"
              :key="index"
              style="margin-top: 15px"
            >
              <v-row>
                <v-col cols="12" sm="6" class="pa-0">
                  <v-row class="form-item">
                    <v-col cols="12" md="4">
                      <label for="first-name">{{ $t("School Name") }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="item.school_name"
                        :rules="validationRules[5].school_name"
                        required
                        @focus="onFocus()"
                        dense
                        solo
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4"
                      ><label for="religion-options">{{ $t("City") }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-autocomplete
                        v-model="item.city"
                        :items="dataObject.city"
                        item-text="name_en"
                        item-value="name_en"
                        :rules="validationRules[5].city"
                        dense
                        solo
                      ></v-autocomplete>
                      <!-- <v-text-field
                    v-model="item.city"
                    :rules="validationRules[5].city"
                    required
                    @focus="onFocus()"
                    dense
                    solo
                    autocomplete="off"
                  ></v-text-field> -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" class="pa-0">
                  <v-row>
                    <v-col cols="12" md="4"
                      ><label for="religion-options">{{
                        $t("Level Completed")
                      }}</label>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-autocomplete
                        v-model="item.level_completed"
                        :items="dataObject.level_completed"
                        item-text="name"
                        item-value="id"
                        :rules="validationRules[5].level_completed"
                        dense
                        solo
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="1" style="margin-left: -3px">
                      <v-checkbox
                        class="mx-2"
                        v-model="studentData.repeated_grade"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="11">
                      <label for="">{{
                        $t("Has the applicant repeated a grade in school")
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="studentData.repeated_grade_name"
                        v-if="studentData.repeated_grade"
                        required
                        @focus="onFocus()"
                        dense
                        :rules="validationRules[5].repeated_grade_name"
                        solo
                        autocomplete="off"
                        :placeholder="$t('Please specify')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -25px">
                    <v-col cols="12" md="1" style="margin-left: -3px">
                      <v-checkbox
                        class="mx-2"
                        v-model="studentData.applicant_expelled"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="11">
                      <label for="">{{
                        $t(
                          "Has the applicant ever been expelled or asked to leave a school?"
                        )
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="studentData.applicant_expelled_details"
                        v-if="studentData.applicant_expelled"
                        required
                        @focus="onFocus()"
                        dense
                        :rules="validationRules[5].applicant_expelled_details"
                        solo
                        autocomplete="off"
                        :placeholder="$t('Please specify')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <hr />
            </div>
            <v-row>
              <!-- add_circle -->
              <v-col cols="12" md="12" style="text-align: center">
                <v-btn
                  large
                  title="Add Another School"
                  class="mr-1 main-btn"
                  @click="addAcademicHistory"
                >
                  {{ $t("Add School") }}</v-btn
                >

                <v-btn
                  large
                  :title="$t('Delete academic history')"
                  class="deleteBtn"
                  @click="deleteAcademic(index)"
                  >{{
                    studentData.academic_history.length == 1 ||
                    studentData.academic_history.length == 0
                      ? $t("Skip")
                      : $t("Delete")
                  }}</v-btn
                >
              </v-col>
            </v-row>
          </section>
          <!-- end section 5 -->
        </v-form>
        <v-form ref="form6" class="student-form" v-model="valid[6]">
          <!-- start section 6 -->
          <section class="form-page profile-section" :hidden="currentStep != 6">
            <h4 style="color: #7295fb">{{ $t("Student Medical History") }}</h4>

            <v-row>
              <v-col cols="12" md="1">
                <v-checkbox
                  class="mx-2"
                  v-model="studentData.special_needs"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="11">
                <label for="" style="margin-top: 4px">{{
                  $t("Does your child have any special needs?")
                }}</label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="studentData.special_needs_details"
                  v-if="studentData.special_needs"
                  required
                  @focus="onFocus()"
                  dense
                  solo
                  autocomplete="off"
                  :placeholder="$t('Please specify')"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="12" md="1">
                <v-checkbox
                  class="mx-2"
                  v-model="studentData.medical_history"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="11">
                <label for="" style="margin-top: 4px;"
                  >Does your child have any medical history?</label
                >
              </v-col>
            </v-row> -->

            <h5 class="main-color mb-0 mt-2">
              Communicable Diseases - أمراض معدية
            </h5>
            <v-row>
              <v-col
                cols="12"
                md="4"
                v-for="(item, index) in dataObject.communicable_diseases"
                :key="index"
                class="diseases"
              >
                <v-checkbox
                  class="mx-2"
                  v-model="studentData.communicable_diseases"
                  :value="item.id"
                >
                  <template slot="label">
                    <label style="display: block" for="">{{
                      item.name_en
                    }}</label>
                    <label class="name_ar">{{ item.name_ar }}</label>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <h5 class="main-color mb-0 mt-2">Other Diseases - أمراض أخرى</h5>
            <v-row>
              <v-col
                cols="12"
                md="4"
                v-for="(item, index) in dataObject.other_diseases"
                :key="index"
                class="diseases"
              >
                <v-checkbox
                  class="mx-2"
                  v-model="studentData.other_diseases"
                  :value="item.id"
                >
                  <template slot="label">
                    <label style="display: block" for="">{{
                      item.name_en
                    }}</label>
                    <label class="name_ar">{{ item.name_ar }}</label>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </section>
        </v-form>
        <!-- end section 6 -->

        <v-form ref="form1" class="student-form">
          <section class="form-page profile-section" :hidden="currentStep != 7">
            <student-attachments
              :studentAttachments="studentAttachments"
              @updateStudentAttachments="updateStudentAttachments"
              :validationMsg="validationMsg"
            ></student-attachments>
          </section>
        </v-form>

        <!-- start section 7 -->
        <v-form ref="form9" class="student-form" v-model="valid[9]">
          <section class="form-page profile-section" :hidden="currentStep != 8">
            <h4 style="margin-bottom: -12px">
              {{ $t("How did you know about us?") }}
            </h4>
            <v-row>
              <v-col cols="12" md="12" class="knowaboutus">
                <v-radio-group v-model="studentData.know_about_us" row>
                  <v-radio value="socialmedia" label="Social Media">
                    <template slot="label">
                      <label for="" style="margin-top: 0"
                        >{{ $t("Social Media") }}
                        <i
                          class="fab fa-facebook"
                          style="margin-left: 5px; color: #7295fb"
                        ></i
                      ></label>
                    </template>
                  </v-radio>
                  <v-radio value="internet" label="Internet">
                    <template slot="label">
                      <label for="" style="margin-top: 0"
                        >{{ $t("Internet") }}
                        <i
                          class="fab fa-internet-explorer"
                          style="margin-left: 5px; color: #7295fb"
                        ></i
                      ></label>
                    </template>
                  </v-radio>
                  <v-radio value="friends" label="Friends">
                    <template slot="label">
                      <label for="" style="margin-top: 0"
                        >{{ $t("Friends") }}
                        <i
                          class="fas fa-users"
                          style="margin-left: 5px; color: #7295fb"
                        ></i
                      ></label>
                    </template>
                  </v-radio>
                  <v-radio value="family" label="Family">
                    <template slot="label">
                      <label for="" style="margin-top: 0"
                        >{{ $t("Family") }}
                        <i
                          class="fas fa-home"
                          style="margin-left: 5px; color: #7295fb"
                        ></i
                      ></label>
                    </template>
                  </v-radio>
                  <v-radio value="other" label="Other"> </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="studentData.know_about_us == 'other'">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="studentData.know_about_us_other"
                  @focus="onFocus()"
                  dense
                  autocomplete="off"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="1" style="margin-left: -7px;">
                <v-checkbox
                  class="mx-2"
                  v-model="studentData.participate_voluntary"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="11">
                <label for="" style="margin-top: 4px;"
                  >Would you like to participate in any school voluntary
                  work?</label
                >
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="studentData.participate_voluntary_details"
                  @focus="onFocus()"
                  dense
                  autocomplete="off"
                  solo
                  v-if="studentData.participate_voluntary"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <div class="text-center">
              <button
                type="button"
                @click.prevent="submitProfile"
                :disabled="disableSubmitButton || !canSubmit()"
                class="ui-btn submit"
              >
                {{ $t("Finish") }}
              </button>
            </div>
          </section>
        </v-form>
        <!-- end section 9 -->

        <v-row class="pagination mx-16">
          <v-col>
            <span
              class="dark-gray previous-text"
              :class="currentStep != 1 ? 'active' : ''"
              @click="previous"
              >{{ $t("Previous") }}</span
            ><v-icon
              @click="previous"
              class="before"
              :disabled="currentStep == 1"
              >navigate_before</v-icon
            ></v-col
          >
          <v-col class="text-right">
            <v-icon @click="next" class="next">navigate_next</v-icon>
            <span class="main-color next-text active">{{ $t("Next") }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="submitted" cols="12" md="6">
        <section class="after-submit">
          <h4>{{ $t("Data has been submitted successfully.") }}</h4>
        </section>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" v-if="messageShow">
        <p class="value">
          "Your profile is completed, if you need to edit your data, please
          Contact the school adminstration "
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CompleteProfileMixin } from "../../mixins/StudentProfile/CompleteProfileMixin";
import { CompleteProfileValidationsMixin } from "../../mixins/StudentProfile/CompleteProfileValidationsMixin";
import { mapGetters } from "vuex";
import studentAttachments from "../../components/completeStudentProfile/studentAttachments.vue";
export default {
  components: { studentAttachments },
  mixins: [CompleteProfileMixin, CompleteProfileValidationsMixin],
  data() {
    return {
      studentAttachments: [],
      validationMsg: "",
      eduLevels: [
        "Less than high school",
        "high school",
        "Associate degree",
        "Bachelor degree",
        "Master degree",
        "Professional degree ",
        "Doctoral degree",
      ],
      step: 1,
      father_saudi: false,
      mother_saudi: false,
      imageError: true,
      messageShow: false,
      // applicantObject
      applicantObject: {
        firstname: "",
        fathername: "",
        familyname: "",
        gender: "",
        religion: "",
        date_of_birth: "",
        date_of_birth_hijri: "",
        place_of_birth: "",
        nationality: "",
        national_id: "",
        passport_no: "",
        iqama_no: "",
        iqama_expiry_date: "",
      },
      // Student Data
      studentData: {
        father: {
          firstname: "",
          middlename: "",
          familyname: "",
          education_level: "",
          occupation: "",
          business_address: "",
          tel: "",
          country_id: 187,
          mobile: "",
          email: "",
          nationality_id: 187,
          national_id: "",
          parent_iqama_no: "",
          parent_iqama_no_expire_date: "",
        },
        mother: {
          firstname: "",
          middlename: "",
          familyname: "",
          education_level: "",
          occupation: "",
          business_address: "",
          tel: "",
          country_id: 187,
          mobile: "",
          email: "",
          nationality_id: 187,
          national_id: "",
          parent_iqama_no: "",
          parent_iqama_no_expire_date: "",
        },
        parents_relationship: "",
        parents_relationship_other: "",
        siblings: [
          {
            name: "",
            gender: "",
            mobile: "",
            country_id: 187,
            date_of_birth: "",
            school: "",
          },
        ],
        academic_history: [
          {
            school_name: "",
            city: "",
            level_completed: "",
            // levels_attended: []
          },
        ],
        repeated_grade: false,
        repeated_grade_name: "",
        applicant_expelled: false,
        applicant_expelled_details: "",
        medical_history: false,
        special_needs: false,
        special_needs_details: "",
        know_about_us: "",
        know_about_us_other: "",
        participate_voluntary: false,
        participate_voluntary_details: "",
        communicable_diseases: [],
        other_diseases: [],
        image: "",
      },
      logoError: "",
      currentStep: 1,
      stepsCount: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: false,
        8: false,
        9: true,
        // 10: true
      },
      valid: [
        true, // 0
        false, // 1
        false, // 2
        false, // 3
        false, // 4
        false, // 5
        false, // 6
        false, // 7
        false, // 8
        false, // 9
        // false // 10
      ],
      submitted: false,
      disableSubmitButton: false,
      applicant_code: "",
      backendUrl: this.getBackEndUrl,
    };
  },
  computed: {
    ...mapGetters(["getBackEndUrl"]),
  },
  mounted() {
    var _this = this;
    this.studentData.siblings.forEach((item, index) => {
      $(document).ready(function () {
        $("#gregorian" + index).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.studentData.siblings[index].date_of_birth = dateH;
            } else {
              _this.studentData.siblings[index].date_of_birth = "";
            }
          },
        });
      });
    });
  },
  watch: {
    "studentData.medical_history": function (v) {
      this.stepsCount[7] = v;
      this.stepsCount[8] = v;
      this.valid[6] = v;
      this.valid[7] = v;
    },
    valid: function () {
      if (this.valid[5]) {
        this.valid[8] = true;
        this.valid[9] = true;
        this.valid[10] = true;
      } else {
        this.valid[8] = false;
        this.valid[9] = false;
        this.valid[10] = false;
      }
    },
    currentStep: function (v) {
      if (v == 9) {
        this.returnIfNotValidStep();
      }
    },
  },
  methods: {
    updateStudentAttachments(attachments) {
      this.studentAttachments = attachments;
    },
    deleteSibling(index) {
      if (this.studentData.siblings.length <= 1) {
        this.studentData.siblings.splice(index, 1);
        this.currentStep = 5;
      } else {
        this.studentData.siblings.splice(index, 1);
      }
    },
    deleteAcademic(index) {
      if (this.studentData.academic_history.length <= 1) {
        this.studentData.academic_history.splice(index, 1);
        this.currentStep = 6;
      } else {
        this.studentData.academic_history.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
.radio-pagination {
  margin-top: 25px;
}
.value {
  color: #dddddd;
}
.nationality,
.religion,
.native,
.secondLang,
.grade {
  position: relative;
}

.select-icon {
  position: absolute;
  top: 56px;
  right: 16px;
}

.success-icon {
  font-size: 100px;
}

.message {
  width: 80%;
  padding: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 17px #d1d1d1 !important;
  box-shadow: 0px 3px 17px #d1d1d1 !important;
  margin: 0 auto;
}
.after-submit {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.after-submit h4 {
  color: #7295fb !important;
}

.custom-file {
  width: 100%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: -2px !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;

    &::after {
      margin-top: -2%;
      // display: inline;
      padding: 3px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 42%;
    }
  }
}
.dateIcon {
  font-size: 28px !important;
  position: absolute !important;
  top: 19px !important;
  right: 9px !important;
}
.countryCode {
  padding-right: 0 !important;
}

/* ============================ new style =============================== */
.side-page {
  svg {
    height: 200px;
  }
}

.main-title {
  color: #928f8f;
  font-weight: bold;
}

.pagination {
  .before {
    color: white !important;
    width: 30px;
    height: 30px;
    background-color: $main-color;
    border-radius: 50%;
    margin: 0 5px;
  }
  .next {
    color: white !important;
    width: 30px;
    height: 30px;
    background-color: $main-color;
    border-radius: 50%;
    margin: 0 5px;
  }

  .previous-text.active,
  .next-text.active {
    cursor: pointer;
  }
  .previous-text,
  .next-text {
    font-size: 1.2rem;
  }
}

.deleteSiblingBtn,
.deleteBtn {
  background-color: #a3a0fb !important;
  color: #ffffff;
}
</style>
